exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-agenda-js": () => import("./../../../src/templates/agenda.js" /* webpackChunkName: "component---src-templates-agenda-js" */),
  "component---src-templates-biography-js": () => import("./../../../src/templates/biography.js" /* webpackChunkName: "component---src-templates-biography-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-boeken-js": () => import("./../../../src/templates/boeken.js" /* webpackChunkName: "component---src-templates-boeken-js" */),
  "component---src-templates-gallerij-js": () => import("./../../../src/templates/gallerij.js" /* webpackChunkName: "component---src-templates-gallerij-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-releases-js": () => import("./../../../src/templates/releases.js" /* webpackChunkName: "component---src-templates-releases-js" */)
}

